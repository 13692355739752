import { t } from "ttag";

import { useSelector } from "metabase/lib/redux";
import { getSetting } from "metabase/selectors/settings";
import type { ButtonProps } from "metabase/ui";
import { Button, Icon } from "metabase/ui";

interface TimeToggleProps extends ButtonProps {
  hasTime: boolean;
  onClick?: () => void;
}

export function TimeToggle({ hasTime, ...props }: TimeToggleProps) {
  const customFormattingSettings = useSelector(state =>
    getSetting(state, "custom-formatting"),
  );
  const initialShowTime =
    customFormattingSettings?.["type/Temporal"]?.show_time;
  const showTime =
    typeof initialShowTime === "undefined" ? true : initialShowTime;

  return (
    <Button
      c="text-medium"
      variant="subtle"
      leftIcon={<Icon name="clock" />}
      style={!showTime ? { visibility: "hidden" } : {}}
      {...props}
    >
      {hasTime ? t`Remove time` : t`Add time`}
    </Button>
  );
}
